import React, { useEffect } from "react";
import "../Assets/Styles/portfolio.css";
import pennyAI from "../Assets/Images/penny.png";
import reacticon from "../Assets/Images/react.png";
import python from "../Assets/Images/python.png";
import figma from "../Assets/Images/figma.png";
import oracle from "../Assets/Images/oracle.png";
import azure from "../Assets/Images/azure.png";
import moksaAi from "../Assets/Images/moksa.png";
import vuejs from "../Assets/Images/viewjs.png";
import angularjs from "../Assets/Images/angular.png";
import nodejs from "../Assets/Images/nodejs.png";
import surveillance from "../Assets/Images/surveillance.png";
import mongodb from "../Assets/Images/mongo.png";
import microsoftNet from "../Assets/Images/microsoftnet.png";
import csharp from "../Assets/Images/csharp.svg";
import inventoryGuru from "../Assets/Images/inventory.png";
import digitalOcean from "../Assets/Images/digitalocean.png";
import nestjs from "../Assets/Images/nest.png";
import postgresql from "../Assets/Images/postgresql.png";
import sqldb from "../Assets/Images/mysql.svg";
import aws from "../Assets/Images/aws.png";
import growthValue from "../Assets/Images/growthvalue.png";
import medmerise from "../Assets/Images/medmerise.png";
import afeedRanker from "../Assets/Images/afeedRanker.png";
import EndSection from "../Components/endSection";
import RanSync from "../Assets/Images/RanSync.png";
import BetterLady from "../Assets/Images/BetterLady.png";
import ClubManagement from "../Assets/Images/ClubManagement.png";
import Stagestock from "../Assets/Images/Stagestock.png";
import Sinozia from "../Assets/Images/Sinozia.png";
import Intero from "../Assets/Images/Intero.png";
import SmartTrack from "../Assets/Images/SmartTrack.png";
import Anothen from "../Assets/Images/Anothen.png";
import { useNavigate } from "react-router-dom";

const Portfolio = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="portfolio">
      <h1 className="title">Portfolio</h1>
      <h2 className="subtitle">
        Explore Our Innovations: Elevating Possibilities Through Creative IT
        Solutions
      </h2>
      <div className="line-sep"></div>
      <div className="page-content">
      <div className="project-section">
          <div className="project-left">
            <div className="mockup-container">
              <img
                className="mockup-img"
                src={Anothen}
                alt="Anothen Mockup"
              />
            </div>
          </div>
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Anothen</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
              Anothen Analytics is a boutique consulting firm specializing in custom
              systems for select clients. Our projects range from hurricane preparedness
              for cities to global security for Fortune 50 companies. Our core project,
              Robbie, is an autonomous trading system, while our labs focus on AI-driven
              trading assistants and advanced capabilities. We're also developing
              educational tools to help others on their trading journey.
              </p>            
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={reacticon}
                    alt="React Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>React JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={oracle}
                    alt="Oracle Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Oracle DB</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={azure} alt="Azure Icon" />
                </div>
                <div className="techno-name">
                  <span>Azure</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div className="project-section-reverse">
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Smart Track</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
              Smart Track offers GPS-based IoT solutions for fleet management,
              including tracking, fuel monitoring, cold chain monitoring, and
              video telematics. Designed for fleet managers and senior management,
              our platform provides a high-level view of fleet status with printable
              data for easy reporting.
              </p>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={reacticon}
                    alt="React Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>React JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={sqldb} alt="MySQL Icon" />
                </div>
                <div className="techno-name">
                  <span>MySQL DB</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={aws} alt="AWS Icon" />
                </div>
                <div className="techno-name">
                  <span>AWS</span>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="project-left">
            <div className="mockup-container">
              <img
                className="mockup-img"
                src={SmartTrack}
                alt="Smart Track Mockup"
              />
            </div>
          </div>
        </div>

      <div className="project-section">
          <div className="project-left">
            <div className="mockup-container">
              <img
                className="mockup-img"
                src={Intero}
                alt="Intero Mockup"
              />
            </div>
          </div>
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Intero</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
              Intero is a web application that digitizes provider and consumer workflows
              by integrating physical sensor inputs. It offers comprehensive data
              management with Create/Read/Update/Delete functionalities, streamlining
              processes for better efficiency and analysis.
              </p>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={reacticon}
                    alt="React Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>React JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={oracle}
                    alt="Oracle Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Oracle DB</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={azure} alt="Azure Icon" />
                </div>
                <div className="techno-name">
                  <span>Azure</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div className="project-section-reverse">
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Sinozia</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
              Transform your voice with Sinozia—a revolutionary voice changer app
              offering diverse character options. Effortlessly modify your voice or
              convert text to speech, bringing your words to life with the perfect tone
              and personality. Customize and experiment with various voices to find the
              ideal match for any situation. Share your creations with friends and leave
              a lasting impression. Elevate your audio experience with Sinozia—where
              innovation meets creativity. Start exploring, transforming, and sharing today!
              </p>
              <a href="https://voice.ai/" target="_blank" className="link">
                Visit Website
              </a>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={reacticon}
                    alt="React Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>React JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={sqldb} alt="MySQL Icon" />
                </div>
                <div className="techno-name">
                  <span>MySQL DB</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={aws} alt="AWS Icon" />
                </div>
                <div className="techno-name">
                  <span>AWS</span>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="project-left">
            <div className="mockup-container">
              <img
                className="mockup-img"
                src={Sinozia}
                alt="Sinozia Mockup"
              />
            </div>
          </div>
        </div>

        <div className="project-section">
          <div className="project-left">
            <div className="mockup-container">
              <img
                className="mockup-img"
                src={Stagestock}
                alt="Stagestock Mockup"
              />
            </div>
          </div>
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Stagestock</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
                Introducing Stagestock—a game-changing web application designed
                to streamline event props management for bulk transport. With
                Stagestock, managing event props has never been easier.
                Effortlessly organize, track, and transport your props for
                events of any scale. Our intuitive platform simplifies inventory
                management, ensuring every prop is accounted for and ready for
                transport. Say goodbye to logistics headaches and hello to
                seamless event preparation. Experience the future of event props
                management with Stagestock. Get started today!
              </p>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={reacticon}
                    alt="React Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>React JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={oracle}
                    alt="Oracle Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Oracle DB</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={azure} alt="Azure Icon" />
                </div>
                <div className="techno-name">
                  <span>Azure</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="project-section-reverse">
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Better Lady</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
                Discover Your Perfect Salon Experience with Better Lady—a
                groundbreaking web application empowering you to explore a
                curated selection of top-tier salons at your fingertips.
                Effortlessly browse through a diverse range of salons, each
                offering unique services tailored to your needs. Book
                appointments with ease, ensuring you secure the perfect time
                slot for your schedule. Leave your mark by rating and reviewing
                your salon experiences, helping fellow users make informed
                decisions. Elevate your salon experience with Better Lady—where
                convenience meets excellence. Start exploring, booking, and
                reviewing today!
              </p>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={reacticon}
                    alt="React Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>React JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={sqldb} alt="MySQL Icon" />
                </div>
                <div className="techno-name">
                  <span>MySQL DB</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={aws} alt="AWS Icon" />
                </div>
                <div className="techno-name">
                  <span>AWS</span>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="project-left">
            <div className="mockup-container">
              <img
                className="mockup-img"
                src={BetterLady}
                alt="Better Lady Mockup"
              />
            </div>
          </div>
        </div>

        <div className="project-section">
          <div className="project-left">
            <div className="mockup-container">
              <img className="mockup-img" src={RanSync} alt="RanSync Mockup" />
            </div>
          </div>
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">RanSync</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
                Ransync revolutionizes your SEO strategy with its sophisticated
                web application, offering comprehensive SEO analysis to uncover
                hidden opportunities and personalized recommendations for
                optimal performance. Seamlessly explore keyword research,
                competitor analysis, and real-time performance tracking to
                streamline your SEO efforts. With an intuitive interface and
                user-friendly experience, Ransync equips both seasoned experts
                and newcomers with the tools and insights needed to dominate the
                digital landscape.
              </p>
              <a href="https://ransync.com/" target="_blank" className="link">
                Visit Website
              </a>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={reacticon}
                    alt="React Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>React JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={oracle}
                    alt="Oracle Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Oracle DB</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={azure} alt="Azure Icon" />
                </div>
                <div className="techno-name">
                  <span>Azure</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="project-section-reverse">
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Afeed Ranker</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
                Elevate your SEO game with our web application—a powerful tool
                enabling users to effortlessly check domain rankings and keyword
                positions. Tailored for precision, it offers insights into each
                domain's performance on diverse keywords, with customizable city
                and country options. Streamline your SEO strategy and achieve
                optimal online visibility. Check it out today!
              </p>
              <a href="https://afeedranker.com/" target="_blank" className="link">
                Visit Website
              </a>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={reacticon}
                    alt="React Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>React JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={postgresql}
                    alt="Oracle Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>PostgreSQL</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={azure} alt="Azure Icon" />
                </div>
                <div className="techno-name">
                  <span>Azure</span>
                </div>
              </div>
            </div>
          </div>
          <div className="project-left">
            <div className="mockup-container">
              <img
                className="mockup-img"
                src={afeedRanker}
                alt="AfeedRanker Mockup"
              />
            </div>
          </div>
        </div>

        <div className="project-section">
          <div className="project-left">
            <div className="mockup-container">
              <img className="mockup-img" src={pennyAI} alt="Penny-AI Mockup" />
            </div>
          </div>
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Penny AI</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
                Transform your freelance career with Penny AI, our specialized
                SaaS solution tailored to streamline bookkeeping, simplify
                invoicing, and ensure tax compliance. Developed by our dedicated
                team, Penny AI empowers freelancers to enhance financial
                management, boost productivity, and reduce administrative
                burdens. Elevate your freelance journey—contact us to
                revolutionize your approach to financial organization today!
              </p>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={reacticon}
                    alt="React Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>React JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={oracle}
                    alt="Oracle Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Oracle DB</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={azure} alt="Azure Icon" />
                </div>
                <div className="techno-name">
                  <span>Azure</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="project-section-reverse">
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Moksa AI</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
                Revolutionizing video surveillance, moksa.AI is an advanced
                project leveraging artificial intelligence. With real-time
                analytics, seamless camera management, and robust role tools, it
                enhances security and operational efficiency. Incorporating
                theft detection algorithms, moksa.AI delivers unparalleled
                precision, empowering users to make smarter decisions in retail
                security, employee management, and customer behavior analysis.
              </p>
              <a href="https://www.moksa.ai/" target="_blank" className="link">
                Visit Product
              </a>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={reacticon}
                    alt="React Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>React JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={sqldb} alt="MySQL Icon" />
                </div>
                <div className="techno-name">
                  <span>MySQL DB</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={aws} alt="AWS Icon" />
                </div>
                <div className="techno-name">
                  <span>AWS</span>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="project-left">
            <div className="mockup-container">
              <img className="mockup-img" src={moksaAi} alt="Penny-AI Mockup" />
            </div>
          </div>
        </div>

        <div className="project-section">
          <div className="project-left">
            <div className="mockup-container">
              <img
                className="mockup-img"
                src={growthValue}
                alt="Growth Value Mockup"
              />
            </div>
          </div>
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Growth Value</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
                Elevate your food-related research with Growth Value, a
                pioneering platform that compiles comprehensive food data from
                diverse websites. Empowering users to search, compare, and
                explore food products effortlessly, Growth Value's user-friendly
                interface and website scraping feature ensure access to the
                latest information. Redefining convenience, it's the ultimate
                solution for informed decision-making in nutrition, culinary
                pursuits, and business operations.
              </p>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={angularjs}
                    alt="Angular Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Angular JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={nestjs} alt="Nest Icon" />
                </div>
                <div className="techno-name">
                  <span>Nest JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={postgresql}
                    alt="PostgreSQL Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>PostgreSQL</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={digitalOcean}
                    alt="DigitalOcean Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Digital Ocean</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="project-section-reverse">
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Medmerise</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
                Empowering GAMSAT aspirants, the GAMSAT Test Portal
                revolutionizes test preparation with timed simulations, progress
                tracking, and a rich question bank. Our user-centric platform
                provides a personalized approach, offering realistic testing
                interfaces and admin tools for seamless management. Elevate
                GAMSAT readiness with data-driven insights and comprehensive
                resources, fostering success for aspiring medical professionals.
              </p>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={vuejs} alt="Vue Icon" />
                </div>
                <div className="techno-name">
                  <span>Vue JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={sqldb} alt="MySQL Icon" />
                </div>
                <div className="techno-name">
                  <span>MySQL DB</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={aws} alt="AWS Icon" />
                </div>
                <div className="techno-name">
                  <span>AWS</span>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="project-left">
            <div className="mockup-container">
              <img
                className="mockup-img"
                src={medmerise}
                alt="Medmerise Mockup"
              />
            </div>
          </div>
        </div>

        <div className="project-section">
          <div className="project-left">
            <div className="mockup-container">
              <img
                className="mockup-img"
                src={inventoryGuru}
                alt="Inventory Mockup"
              />
            </div>
          </div>
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Inventory Guru</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
                Revolutionize your restaurant's efficiency with our tailored
                inventory management web application. Real-time tracking,
                automated alerts, and seamless integration optimize stock
                levels, reduce waste, and enhance communication with suppliers.
                Our collaboration with a restaurant owner resulted in a
                successful project, enabling data-driven decisions, cost
                reduction, and increased profitability. Invest in customized web
                applications for streamlined operations—contact us for success.
              </p>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={figma} alt="Figma Icon" />
                </div>
                <div className="techno-name">
                  <span>Figma</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={reacticon}
                    alt="React Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>React JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={python}
                    alt="Django Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Django</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={sqldb} alt="MySQL Icon" />
                </div>
                <div className="techno-name">
                  <span>MySQL</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={aws} alt="AWS Icon" />
                </div>
                <div className="techno-name">
                  <span>AWS</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="project-section-reverse">
          <div className="project-right">
            <div className="project-title">
              <h2 className="project-title">Surveillance Services</h2>
            </div>
            <div className="project-desc">
              <p className="project-desc">
                Experience unparalleled security with Surveillance Services—an
                innovative app by a leading theft detection company. Offering
                universal camera integration, multi-camera viewing, and live
                stream recording, it revolutionizes surveillance. With robust
                store and camera management, plus local and server-based
                backups, it sets the industry standard for comprehensive and
                secure solutions. Elevate your security infrastructure—contact
                us for a revolution in theft detection and surveillance.
              </p>
            </div>

            <div className="technologies">
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={csharp} alt="C# Icon" />
                </div>
                <div className="techno-name">
                  <span>C#</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={microsoftNet}
                    alt=".Net Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>.Net</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={nodejs}
                    alt="NodeJS Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Node JS</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img
                    className="techno-image"
                    src={mongodb}
                    alt="MongoDB Icon"
                  />
                </div>
                <div className="techno-name">
                  <span>Mongo DB</span>
                </div>
              </div>
              <div className="techno">
                <div className="techno-image">
                  <img className="techno-image" src={aws} alt="AWS Icon" />
                </div>
                <div className="techno-name">
                  <span>AWS</span>
                </div>
              </div>
            </div>
          </div>
          <div className="project-left">
            <div className="mockup-container">
              <img
                className="mockup-img"
                src={surveillance}
                alt="Penny-AI Mockup"
              />
            </div>
          </div>
        </div>
      </div>

      <EndSection />
    </div>
  );
};

export default Portfolio;
